import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { isNumeric } from '../common';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

@Injectable({
  providedIn: 'root'
})
export class ShareService {
  private deviceSubject = new BehaviorSubject<any>({});
  public totalCDN = new BehaviorSubject<any>(null);
  public campaignIdCdn = new BehaviorSubject<any>(null);
  deviceId = this.deviceSubject.asObservable();
  // showCDN = this.totalCDN.asObservable();

  constructor(
    private httpClient: HttpClient
  ) {

    this.deviceSubject = new BehaviorSubject<string>(null);
    this.deviceId = this.deviceSubject.asObservable();
    this.getDeviceId();
  }

  getDeviceId() {
    // nhận deviceId từ url
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let deviceId = urlParams.get('deviceId');

    if (!deviceId || deviceId === 'null' || deviceId === 'undefined') {
      deviceId = localStorage.getItem('device_id');
    }
    if (!deviceId || deviceId === 'null' || deviceId === 'undefined') {
      FingerprintJS.load().then(fp => {
        fp.get().then(async result => {
          deviceId = await result.visitorId;
          localStorage.setItem('device_id', deviceId);
          this.deviceSubject.next(deviceId);
          // tạm thời cho reload lại hj
          window.location.reload();
        });
      });
    } else {
      localStorage.setItem('device_id', deviceId);
      this.deviceSubject.next(deviceId);
    }
  }

  headerLocation: any = {};
  setHeader(location: any) {
    this.headerLocation = Object.assign({}, location);
  }
  loginCore(body?: any): Observable<any> {
    return this.httpClient.post<any>(`${environment.urlDev}/api/v1/users/login`, body);
  }
  registerCore(body?: any): Observable<any> {
    return this.httpClient.post<any>(`${environment.urlDev}/api/v1/users/register`, body);
  }
  sendOtpRegister(body?: any, token?: string): Observable<any> {
    const header = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      ...this.headerLocation
    });
    return this.httpClient.post<any>(`${environment.urlDev}/api/v1/users/send-otp-confirm-phone`, body, { headers: header }).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }
  confirmOtpRegister(body: any, token?: string) {
    const header = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      ...this.headerLocation
    });
    return this.httpClient.post<any>(`${environment.urlDev}/api/v1/users/confirm-phone`, body, { headers: header })
  }
  retryOtp(body?: any): Observable<any> {
    return this.httpClient.post<any>(`${environment.urlDev}/api/v1/users/send-otp`, body).pipe(
      catchError(err => {
        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
  resetPassowrd(body?: any): Observable<any> {
    return this.httpClient.post<any>(`${environment.urlDev}/api/v1/users/reset-password`, body).pipe(
      catchError(err => {
        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
  getOtp(body): Observable<any> {
    let object: any = {};
    if (body.target && body.target !== 'null') {
      if (!isNumeric(body.target)) {
        object.target = decodeURIComponent(body.target);
      } else {
        object.target = `${environment.urlTemplateMKT}${body.target}`;
      }
    }
    object.phone = body.phone;
    if (body?.campaign_id) {
      object.campaign_id = body.campaign_id;
    }
    if (body?.user_id) {
      object.user_id = body.user_id;
    }
    return this.httpClient.post(`${environment.urlDev}${environment.public}campaign/get-otp`, object);
  }
  sendOtp(body): Observable<any> {
    return this.httpClient.post<any>(`${environment.urlDev}${environment.public}campaign/get-otp-token`, body);
  }
  getListNetwork(): Observable<any> {
    return this.httpClient.get<any>(`${environment.urlDev}${environment.public}campaign/topup/service`);
  }

  // tslint:disable-next-line:variable-name
  getBusinessCampaign(params): Observable<any> {
    return this.httpClient.get<any>(`${environment.urlDev}${environment.public}campaign/business-campaign`, {params});
  }

  getBusiness(businessId) {
    return this.httpClient.get<any>(`${environment.urlDev}${environment.public}business/${businessId}/info`);
  }

  getScenario(id) {
    return this.httpClient.get<any>(`${environment.urlDev}${environment.public}campaign/${id}/scripts`);
  }

  getAllGift(campaignId, params) {
    return this.httpClient.get(`${environment.urlDev}${environment.public}campaign/${campaignId}/all-gift`, { params });
  }

  getCampaign(campaignId) {
    const object: any = {
      description: true
    };
    if (campaignId) {
      object.box = true;
      return this.httpClient.get(`${environment.urlDev}${environment.public}campaign/${campaignId}`, {params: object});
    }
  }
  getHistory(campaignId, params) {
      return this.httpClient.get(`${environment.urlDev}${environment.public}campaign/${campaignId}/activities`, {params});
  }

  hasOTP = new BehaviorSubject<boolean>(false);
  currentHasOTP = this.hasOTP.asObservable();
  sendOTPDone(otp: boolean) {
    this.hasOTP.next(otp);
  }
}
