import { Component, OnInit, AfterViewInit, Input, OnChanges } from '@angular/core';
@Component({
    selector: 'app-redirect-url',
    templateUrl: './redirect-url.component.html',
    styleUrls: ['./redirect-url.component.scss'],
})
export class RedirectUrlComponent implements OnInit, AfterViewInit {

    @Input() redirect_action;

    @Input() isTimeGift;

    public time_out;
    public count_win = 0;
    public isCountWin = false;
    public isCountGift = false;
    public count_gift = 0;

    constructor() { }

    async ngOnInit() {
        if (localStorage.getItem('redirect_action')) {
            this.redirect_action = JSON.parse(localStorage.getItem('redirect_action'));
        }
        this.count_win = JSON.parse(localStorage.getItem('count_win'));
        this.count_gift = JSON.parse(localStorage.getItem('count_gift'));
        if (this.redirect_action?.action_type === 'WIN' && this.count_win >= this.redirect_action?.redirect_times) {
            this.isCountWin = true;
            return;
        }
        
        if (this.redirect_action?.action_type === "RECEIVE_GIFT" && this.count_gift >= this.redirect_action?.redirect_times) {
            this.isCountGift = true;
            return;
        }

        this.time_out = this.redirect_action?.redirect_timeout;
        if (this.time_out && this.redirect_action?.is_enabled) {
            const interval = await setInterval(() => {
                --this.time_out;
                if (this.time_out == 0) {
                    clearInterval(interval)
                    this.isCountWin = true;
                    this.isCountGift = true;
                }
            }, 1000);


            await setTimeout(() => {
                if (this.redirect_action?.action_type === 'WIN') {
                    this.count_win = this.count_win + 1;

                    localStorage.setItem('count_win', JSON.stringify(this.count_win));
                }
                if (this.redirect_action?.action_type === 'RECEIVE_GIFT') {
                    this.count_gift = this.count_gift + 1;
                    localStorage.setItem('count_gift', JSON.stringify(this.count_gift));
                }
                localStorage.removeItem('redirect_action');
                window.open(`${this.redirect_action?.redirect_url}`, '_parent');
            }, (this.redirect_action?.redirect_timeout) * 1000);
        }
    }

    ngAfterViewInit(): void {

    }

    ngOnDestroy() {
        localStorage.removeItem('redirect_action');
    }

}