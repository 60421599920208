import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ModalController} from '@ionic/angular';
import {ShareService} from '../../share/services';
import {CommonService} from '../../../public/service/common.service';
import {Title} from '@angular/platform-browser';
import * as moment from 'moment';
import {userIcheck} from '../../../helpers/userIcheck';
import {OtpComponent, VerifyPhoneComponent} from '../../share';

@Component({
  selector: 'app-my-gift-and-history',
  templateUrl: './my-gift-and-history.component.html',
  styleUrls: ['./my-gift-and-history.component.scss'],
})
export class MyGiftAndHistoryComponent implements OnInit {
  constructor(private route: ActivatedRoute,
              public modalController: ModalController,
              private shareService: ShareService,
              private commonService: CommonService,
              private titleService: Title,
              private router: Router) {
    // get path
    this.campaignId = this.route.snapshot.params.id;
    this.businessId = this.route.snapshot.params.businessId;
    this.is_change_phone = this.route.snapshot.queryParams.is_change_phone;
    if (this.route.snapshot.queryParams.activeTab) {
      this.activeTab = parseInt(this.route.snapshot.queryParams.activeTab);
    }
    
    this.shareService.deviceId.subscribe(deviceId => this.deviceId = deviceId);
    //get query param
    if (this.route.snapshot.queryParams?.token) {
      localStorage.setItem('otpToken', this.route.snapshot.queryParams?.token);
      localStorage.setItem('userVery', JSON.stringify([this.phone]));
    }
    this.phone = this.route.snapshot.queryParams.phone;
  }
  isUserIcheck = false;
  campaignId: any;
  businessId: any;
  is_change_phone: any;
  deviceId: any;
  phone: any;
  activeTab: number = 1;
  gifts: any = [];
  histories: any = [];
  campaign: any;
  business: any;
  isLoading: boolean = true;

  offset: number = 0;
  offsetGift: number = 0;
  countTotal: number = 0;
  countTotalGift: number = 0;

  async ngOnInit() {
    this.isLoading = true;
    await this.loadBusiness();
    await this.loadCampaign();

    if (this.is_change_phone === 'false') {
      await this.loadAllGift();
      await this.loadHistory();
      this.isLoading = false;
      return;
    }
    if (this.campaign?.campaign_condition?.web_app_condition === 'info_otp_required' && !localStorage.getItem('otpToken')) {
      // cần xác mình sdt khi yêu cầu OTP
      const modal = await this.modalController.create({
        // component: VerifyPhoneComponent,
        component: OtpComponent,
        cssClass: 'popup-confirm-phone',
        backdropDismiss: false,
        componentProps: {
          // campaign_id: this.campaignId,
          user_id: this.campaign?.owner?.id,
          phoneDefault: this.phone
        }
      });
      modal.onDidDismiss().then(async data => {
        if (data?.data?.phone) {
          await this.router.navigate([`campaign/${this.campaignId}`], {queryParams: {phone: data?.data?.phone}});
          await this.loadAllGift();
        } else if (data?.data?.isSamePhone) {

        } else {
          // this.location.back();
          location.reload();
        }
      });
      return await modal.present();
    }

    if (this.campaign?.campaign_condition?.web_app_condition === 'info_required' && !this.phone) {
      // cần xác phone khi yêu cầu nhập thông tin tham gia chương trình
      const modal = await this.modalController.create({
        component: VerifyPhoneComponent,
        cssClass: 'popup-confirm-phone',
        backdropDismiss: false,
        componentProps: {
          phone: this.phone
        }
      });
      modal.onDidDismiss().then(async data => {
        if (data?.data?.phone) {
          this.phone = data?.data?.phone;
          await this.router.navigate([`campaign/${this.campaignId}/${this.campaign?.owner?.id}/my-gift-and-history`], {queryParams: {phone: data?.data?.phone}});
          await this.loadAllGift();
        } else {
          location.reload();
        }
      });
      return await modal.present();
    }


    await this.loadAllGift();
    await this.loadHistory();
    this.isLoading = false;
  }

  async onLanding() {
    await this.router.navigate([`landing/${this.businessId}`], {queryParams: {phone: this.phone}});
  }

  async onViewCampaign() {
    // tslint:disable-next-line:variable-name
    const is_change_phone = JSON.parse(localStorage.getItem('is_change_phone'));
    // tslint:disable-next-line:max-line-length
    await this.router.navigate([`campaign/${this.campaignId}`], {queryParams: {phone: this.phone, business_id: this.businessId, is_change_phone: this.is_change_phone || is_change_phone}});
  }

  async onGiftDetail(gift) {
    if (moment().isAfter(moment(gift?.campaign?.export_gift_to))) {
      return;
    }
    await this.router.navigate([`gift-detail/${gift?.campaign_id}/undefined/1/null/${gift?.gift_id}/${gift?.winner_id}/${gift?.id}`],
        {queryParams: {phone: this.phone,  parent_campaign_id: this.campaignId }});
  }

  isShowStatusGift(gift) {
    return moment().isAfter(moment(gift?.campaign?.export_gift_to));
  }

  getStatusVoucher(voucher) {
    return this.commonService.getStatusVoucher(voucher);
  }

  getStatusLottery(voucher){
    return this.commonService.getStatusLottery(voucher);
  }
  loadAllGift() {
    const body = {} as any;
    if (this.phone) {
      body.phone = this.phone;
    }
    body.device_id = this.deviceId;
    body.offsetGift = this.offsetGift || 0;
    body.limit = 10;
    return this.shareService.getAllGift(this.campaignId, body).toPromise().then((res: any) => {
      if (res?.statusCode === 200) {
        this.gifts = this.gifts.concat(res?.data?.rows);
        // this.gifts = res?.data?.rows;
        this.countTotalGift = res?.data?.count;
        // this.isLoading = true;
      }
    });
  }
  loadHistory() {
    const params = {} as any;
    params.offset = this.offset;
    params.limit = 10;
    if (this.phone) {
      params.phone = this.phone;
    }
    params.deviceId = this.deviceId;
    return this.shareService.getHistory(this.campaignId, params).toPromise().then((res: any) => {
      if (res?.statusCode === 200) {
        this.histories = this.histories.concat(res?.data?.rows);
        // this.histories = res?.data?.rows;
        this.histories = this.histories.map(item => {
          return {
            ...item,
            rewards: item.gifts.map(gift => gift?.value ? `${gift.value} ${gift.type === 'mini_game_qr_mar' ? 'lượt quay' : (gift.type === 'accumulate_point_qr_mar' ? 'điểm' : '')}` : 'Không trúng quà')
          };
        });
        this.countTotal = res?.data?.count;

        console.log("xxxxxxxxxxxxxx", this.histories)
      }
    });
  }

  loadBusiness() {
    return this.shareService.getBusiness(this.businessId).toPromise().then((res: any) => {
      if (res?.statusCode === 200) {
        this.business = res?.data;
      }
    });
  }

  loadCampaign() {
    return this.shareService.getCampaign(this.campaignId).toPromise().then((res: any) => {
      if (res?.statusCode === 200) {
        this.campaign = res?.data;
        if (userIcheck(res.data)){
          this.isUserIcheck = true;
        }
      }
    });
  }

  async onScrollDown() {
    if (this.countTotal >= this.offset) {
      this.offset = this.offset + 10;
      await this.loadHistory();
    }
  }

  async onScrollDownGift() {
    if (this.countTotalGift >= this.offsetGift) {
      this.offsetGift = this.offsetGift + 10;
      await this.loadHistory();
    }
  }
}
