import {ShareService} from './layout/share/services/share.service';
import {AfterViewInit, Component, OnInit, Renderer2} from '@angular/core';
import {Geolocation} from '@ionic-native/geolocation/ngx';
import {Platform} from '@ionic/angular';
import {environment} from '../environments/environment';
import {isMobile} from './helpers/tracking-teko';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  constructor(
    private geolocation: Geolocation,
    private shareService: ShareService,
    public platform: Platform,
    private renderer2: Renderer2,
    private route: ActivatedRoute,
  ) {
    // let location: any = {};
    // this.geolocation.getCurrentPosition()
    // .then(
    //   (resp) => {
    //   location.lat = resp.coords.latitude;
    //   location.lon = resp.coords.longitude;
    //   localStorage.setItem('location', JSON.stringify(location));
    // })
    // .catch((error) => {
    //   console.log('Error getting location', error);
    //   localStorage.removeItem('location');
    // });
  }

  ngOnInit(): void {
    this.addTrackingId();

    // // Tích hợp iframe
    // (window as any).track('hookMessage', (body) => {
    //   console.log('messeage', body);
    // });
    //
    // // end
  }

  addTrackingId() {
    const s = this.renderer2.createElement('script');
    s.type = 'text/javascript';
    s.text = `
      !function(t,e,r,n,s,a,o,c){t[s]||(t.GlobalTrackerNamespace=t.GlobalTrackerNamespace||[],t.GlobalTrackerNamespace.push(s),t.GlobalTrackerNamespace.push("https://tracking.tekoapis.com"),t[s]=function(){(t[s].q=t[s].q||[]).push(arguments)},t[s].q=t[s].q||[],(o=e.createElement(r)).async=!0,o.src=n,(c=e.getElementsByTagName(r)[0]).parentNode.insertBefore(o,c),window.onerror=function(e,r,a,o,c){if(r===n)return t[s]("exception",{msg:e,error:c}),!1})}(window,document,"script","https://cdn.tekoapis.com/tracker/dist/v2/tracker.full.min.js","track");
      track("init", '${isMobile() ? environment.tracking_id_mobile : environment.tracking_id_web}' )
      track("enableLinkClick");
      track('enableSubmitForm');
      track('enableFocusForm');
    `;
    document.getElementsByTagName('head')[0].appendChild(s);
  }

  isShowCDN: boolean = false;
  ngAfterViewInit(): void {

    // this.route.queryParams
    //     .subscribe(params => {
    //           if ((params?.parent_campaign_id || params?.campaignId) && params?.isIframe) {
    //             this.isShowCDN = true;
    //             (window as any).checkCampaign(params?.parent_campaign_id || params?.campaignId, null, null, null);
    //           }
    //         }
    //     );
    this.shareService.totalCDN.subscribe(change => {
      if (change) {
        this.isShowCDN = true;
        (window as any).checkCampaign(this.shareService.campaignIdCdn.getValue(), null, null, null);
      }
    });
  }

  //  isMobile() {
  //   try { document.createEvent('TouchEvent'); return true; }
  //   catch (e) { return false; }
  // }
}
