export const environment = {
  production: true,
  // oldUrlDev: 'https://campaign-api.vnpayqr.vn/api/business/public/loyalty',
  urlDev: 'https://campaign-api.vnpayqr.vn',
  public: '/api/business/public/loyalty/',
  auth: '/api/business/loyalty/',
  urlTemplateMKT: 'https://qr.icheck.vn/?q=',
  openAppiCheck: 'https://icheck.page.link/home',
  openAppiCheckLucky: 'icheck://icheck_missions',
  openAppiCheckReward: 'icheck://my_rewards',
  isVersionViettel: false,
  userIcheck: {userId: [396]},
  phongvu: {
    isEnable: true,
    userIds: [252, 38],
    wheelItemColor: [['#E65491', '#216BFF'], ['#FFFFFF', '#47C5FF']],
    wheelInnerStroke: ['#908e96', '#FFFFFF'],
    wheelCoverStroke: ['#c8d6f4', '#4c8dff', '#36abe0', '#ffffff'],
    landingUrl: 'http://pv-landing.icheck.vn'
  },
  tracking_id_web: '15fdc33d-7a59-49d4-9d0c-bf95fe64eb70',
  tracking_id_mobile: '03d5df91-0d76-4fad-b192-636f5f117054',
};
