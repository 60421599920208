export const API = {
  urlDev: 'https://api.dev.icheck.vn',
  public: '/api/business/public/loyalty/',
  auth: '/api/business/loyalty/'
}
export const STEP_LANDING_PAGE = [
  {
    image: '../../../../assets/images/luckywheel/step1/group_2804.png',
    image2x: '../../../../assets/images/luckywheel/step1/group_2804@2x.png',
    image2xCode: '../../../../assets/images/luckywheel/step1/ic_onboard_step_1_2.png',
    image3x: '../../../../assets/images/luckywheel/step1/group_2804@3x.png',
    title: 'Quét QR code, nhận cơ hội tốt',
    titleCode: 'Nhập mã may mắn, nhận cơ hội tốt',
    note: 'Bạn ơi! Quét QR code trên sản phẩm để tham gia minigame có thưởng nhé!',
    noteCode: 'Bạn ơi! Nhập mã may mắn in trên sản phẩm để tham gia minigame có thưởng nhé!',
    button: [
      {
        title: 'Tiếp tục',
        acctive: true
      }
    ]
  },
  {
    image: '../../../../assets/images/luckywheel/step2/group_2803.png',
    image2x: '../../../../assets/images/luckywheel/step2/group_2803@2x.png',
    image3x: '../../../../assets/images/luckywheel/step2/group_2803@3x.png',
    title: 'Chơi game hay, quà liền tay',
    note: 'Nhanh tay thử vận may với vòng quay may mắn nào bạn ơi!',
    button: [
      {
        title: 'Tiếp tục',
        acctive: true
      },
      {
        title: 'Quay lại',
        acctive: true
      }
    ]
  },
  {
    image: '../../../../assets/images/luckywheel/step3/group_2802.png',
    image2x: '../../../../assets/images/luckywheel/step3/group_2802@2x.png',
    image3x: '../../../../assets/images/luckywheel/step3/group_2802@3x.png',
    title: 'Wow! Trúng thưởng rồi!',
    note: 'Thật tuyệt vời, bạn đã nhận được phần quà từ nhà tài trợ rồi đấy!',
    button: [
      {
        title: 'Tôi đã hiểu',
        acctive: true
      },
      {
        title: 'Quay lại',
        acctive: true
      }
    ]
  }
]

export const typeStamp = {
  basic: 1,
  marketing: 2,
}

export const typeGift = {
  ICOIN: 'Điểm VNPoint',
  PRODUCT: 'Quà hiện vật',
  CONGRATULATION: 'Quà tinh thần',
  PHONE_CARD: 'Quà thẻ điện thoại',
  RECEIVE_STORE: 'Quà nhận tại cửa hàng',
  VOUCHER: 'Voucher',
  VNPAY_QR: 'Mã VNPAY_QR'
}
